import { DataFrame, PanelData } from '@grafana/data';
import { getColor } from './customColors';
import { SimpleOptions } from 'types';

const headingIndex = 0; // headings in fields(0)
const amountIndex = 1; // amounts = fields(1)

export const buildMultipleDataSets = (pData: PanelData, options: SimpleOptions) => {
  const { series } = pData;
  const { groupLabels, dataSets, dataSetLabels, intervalTotals } =
    options.groupBy === 'Field' ? groupByField(series, options) : groupByValue(series, options);

  console.log('DATASETS >>> ', dataSets);

  return {
    datasets: dataSets.map((ds, i) => {
      const masterValues = [...ds];
      const isMaster = options.master === dataSetLabels[i];
      return {
        backgroundColor:
          options.colorMode === 'Group'
            ? ds.map((col, j) => getColor(j, options.colorPalette))
            : getColor(i, options.colorPalette),
        borderColor:
          options.colorMode === 'Group'
            ? ds.map((col, j) => getColor(j, options.colorPalette))
            : getColor(i, options.colorPalette),
        borderWidth: 1,
        data: isMaster
          ? ds.map((value, valueIndex) => dataSets.map((d) => d[valueIndex]).reduce((a, b) => a - b, value * 2))
          : ds.map((value, i) => (options.type === 'PercentStack' ? valueToPercent(value, intervalTotals[i]) : value)),
        rawData: options.type === 'PercentStack' ? ds : [],
        fill: false,
        isMaster,
        label: isMaster ? 'Others' : dataSetLabels[i],
        lineTension: parseFloat(options.lineTension),
        spanGaps: options.spanGaps,
        masterValues,
      };
    }),
    labels: groupLabels,
  };
};

const valueToPercent = (value: number, total: number) => {
  return Math.floor((value / total) * 10000) / 100;
};

const groupByField = (series: DataFrame[], options: SimpleOptions) => {
  const groupLabels: string[] = extractLabelsFromFields(series, options.fieldOrder);
  const dataSetLabels: string[] = extractLabelsFromValues(series, options.valueOrder);
  const { dataSets, intervalTotals } = buildDataSets(series, 'Field', dataSetLabels, groupLabels);

  return { groupLabels, dataSets, dataSetLabels, intervalTotals };
};

const groupByValue = (series: DataFrame[], options: SimpleOptions) => {
  const groupLabels: string[] = extractLabelsFromValues(series, options.valueOrder);
  const dataSetLabels: string[] = extractLabelsFromFields(series, options.fieldOrder);
  const { dataSets, intervalTotals } = buildDataSets(series, 'Value', dataSetLabels, groupLabels);

  return { groupLabels, dataSets, dataSetLabels, intervalTotals };
};

const extractLabelsFromValues = (series: DataFrame[], order: string) => {
  const labelSet: Set<string> = new Set();
  series.map((query) => query.fields[headingIndex].values.toArray().map((value) => labelSet.add(value)));

  return [...labelSet].sort((a, b) => getSortOrder(order, a) - getSortOrder(order, b));
};

const extractLabelsFromFields = (series: DataFrame[], order: string) => {
  const labelArray: string[] = series.map((query) => query.fields[amountIndex].name);

  return labelArray.sort((a, b) => getSortOrder(order, a) - getSortOrder(order, b));
};

const getSortOrder = (order: string, key: string) => {
  return order ? (order.indexOf(String(key)) === -1 ? 99999 : order.indexOf(String(key))) : -1;
};

const buildDataSets = (series: DataFrame[], type: string, dataSetLabels: string[], groupLabels: string[]) => {
  const intervalTotals: number[] = Array(groupLabels.length).fill(0);
  const dataSets: number[][] = Array(dataSetLabels.length)
    .fill(0)
    .map(() => Array(groupLabels.length).fill(0));
  series.map((query) => {
    query.fields[1].values.toArray().map((value, index) => {
      const dataSetName =
        type === 'Value' ? query.fields[amountIndex].name : query.fields[headingIndex].values.toArray()[index];
      const groupName =
        type === 'Value' ? query.fields[headingIndex].values.toArray()[index] : query.fields[amountIndex].name;
      dataSets[dataSetLabels.indexOf(dataSetName)][groupLabels.indexOf(groupName)] = value;
      intervalTotals[groupLabels.indexOf(groupName)] += value;
    });
  });

  return { dataSets, intervalTotals };
};
