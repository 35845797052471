import React from 'react';

import { PanelData } from '@grafana/data';

interface CustomVisualDataProps {
  data: PanelData;
}

// series(x).fields(y).values(z) => x Queries, with y Columns and z Rows.

export class CustomVisualData extends React.Component<CustomVisualDataProps> {
  render() {
    const { series } = this.props.data;

    return (
      <div className="tables" style={{ display: 'flex', justifyContent: 'center' }}>
        {series.map((query, x) => this.renderTable(query, x))}
      </div>
    );
  }

  private renderValue(name: string, value: any) {
    return name === 'Time' ? new Date(value).toString() : value;
  }

  private renderTable(query: any, x: number) {
    const maxCols = 5;

    return (
      <div style={{ padding: 10 }}>
        <p>
          <strong>
            Series {x} (refId: {query.refId})
          </strong>
        </p>
        <table className="chart-data">
          <tr>
            <th className="chart-data__head-left">Fields</th>
            {query.fields[0].values.toArray().map((value: any, z: number) => {
              if (z <= maxCols || z === query.fields[0].values.toArray().length - 1) {
                return <th key={z} className="chart-data__head-center">{`Value ${z}`}</th>;
              } else {
                return '';
              }
            })}
          </tr>
          {query.fields.map((field: any, y: number) => {
            return (
              <tr key={y}>
                <th className="chart-data__head-left">{field.name}</th>
                {field.values.toArray().map((value: any, z: number) => {
                  if (z <= maxCols || z === field.values.toArray().length - 1) {
                    return <td className="chart-data__cell">{this.renderValue(field.name, value)}</td>;
                  } else {
                    return '';
                  }
                })}
              </tr>
            );
          })}
        </table>
      </div>
    );
  }
}

export default CustomVisualData;
