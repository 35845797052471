import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import { buildMultipleDataSets } from 'util/customData';

import {
  zeroWhy,
  uiLineOptions,
  uiBarOptions,
  uiDoughnutOptions,
  uiPieOptions,
  uiPolarOptions,
  uiBarGroupOptions,
  uiBarStackOptions,
  uiPercentStackOptions,
} from '../util/customOptions';

import CustomVisualData from './CustomVisualData';
import CustomLegend from './CustomLegend';

import { Bar, Doughnut, Polar, HorizontalBar, Pie, Line } from 'react-chartjs-2';
import Chart, { ChartOptions } from 'chart.js';

import '../styles/styles.scss';

interface Props extends PanelProps<SimpleOptions> {}

export const CustomMulti: React.FC<Props> = ({ options, data, width, height }) => {
  const uiData = buildMultipleDataSets(data, options);

  Chart.Tooltip.positioners.zeroWhy = zeroWhy;

  // customise base chart settings with grafana option settings
  const decorateOptions = (chartOptions: ChartOptions) => {
    const newChartOptions = { ...chartOptions };
    if (options.legendType !== 'Standard') {
      if (newChartOptions.legend) {
        newChartOptions.legend.display = false;
      }
    }
    if (newChartOptions.scales && newChartOptions.scales.yAxes && newChartOptions.scales.yAxes[0]) {
      if (newChartOptions.scales.yAxes[0].ticks) {
        if (options.maxYValue) {
          newChartOptions.scales.yAxes[0].ticks.max = parseInt(options.maxYValue, 10);
        }
        newChartOptions.scales.yAxes[0].ticks.callback = (value) =>
          options.yAxesLabel ? options.yAxesLabel.replace('v', value.toString()) : value;
      }
    }

    return newChartOptions;
  };

  const renderChart = (uiData: {}) => {
    switch (options.type) {
      case 'Line':
        return <Line data={uiData} options={decorateOptions(uiLineOptions)} />;
      case 'Polar':
        return <Polar data={uiData} options={decorateOptions(uiPolarOptions)} />;
      case 'Pie':
        return <Doughnut data={uiData} options={decorateOptions(uiPieOptions)} />;
      case 'Gauge':
        return <Pie data={uiData} options={decorateOptions(uiDoughnutOptions)} />;
      case 'HorizontalBar':
        return <HorizontalBar data={uiData} options={decorateOptions(uiBarOptions)} />;
      case 'GroupedBar':
        return orientate(options.orientation, uiData, decorateOptions(uiBarGroupOptions));
      case 'StackedBar':
        return orientate(options.orientation, uiData, decorateOptions(uiBarStackOptions));
      case 'PercentStack':
        return orientate(options.orientation, uiData, decorateOptions(uiPercentStackOptions));
      default:
        return <CustomVisualData data={data} />;
    }
  };

  const orientate = (orientation: string, data: {}, options: {}) => {
    return orientation === 'v' ? (
      <Bar data={data} options={options} />
    ) : (
      <HorizontalBar data={data} options={options} />
    );
  };

  return (
    <div className="rradar-multi">
      <div className="chart-wrapper">
        <div className="chart" style={{ width, height: height - 60 }}>
          {renderChart(uiData)}
        </div>
      </div>
      {options.legendType !== 'Standard' && options.type !== 'Data' && (
        <CustomLegend uiData={uiData} options={options} />
      )}
    </div>
  );
};
