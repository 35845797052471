import React from 'react';

import { getColor } from '../util/customColors';
import { SimpleOptions } from '../types';

interface CustomLegendProps {
  uiData: any;
  options: SimpleOptions;
}

export class CustomLegend extends React.Component<CustomLegendProps> {
  render() {
    return (
      <div className="chart-legend">
        <div className="chart-legend__groups">
          {this.props.uiData.labels.map((group: string, groupId: number) => this.renderGroup(group, groupId))}
        </div>
      </div>
    );
  }

  private renderGroup = (group: string, groupId: number) => {
    return (
      <div key={groupId} className="chart-legend__group">
        <div className="chart-legend__label">{group}</div>
        <div className="chart-legend__values">
          {this.props.uiData.datasets.map((ds: any, dsId: number) => this.renderValue(ds, groupId, dsId))}
        </div>
      </div>
    );
  };

  private renderValue = (ds: any, groupId: number, dsId: number) => {
    const separator = ds.isMaster ? '/' : dsId > 0 ? ':' : '';
    const value = ds.isMaster ? ds.masterValues[groupId] : ds.data[groupId];
    const colorid = this.props.options.colorMode === 'Dataset' ? dsId : groupId;
    const style = ds.isMaster ? {} : { color: getColor(colorid, this.props.options.colorPalette) };
    const label = this.props.options.legendType === 'Custom' ? '' : ds.isMaster ? '' : ` ${ds.label}`;
    return (
      <span key={dsId} className="chart-legend__item">
        <span className="chart-legend__separator">{separator}</span>
        <span className="chart-legend__value" style={style}>
          {label} {value}{' '}
        </span>
      </span>
    );
  };
}

export default CustomLegend;
